<template>
    <v-dialog :persistent="deletingAccount" v-model="deleteAccountDialog" max-width="700">
        <template v-slot:activator="{ on }">
            <v-btn v-on="on" depressed color="error">delete</v-btn>
        </template>
        <v-card flat>
            <v-card-title>
                <div style="width:100%" class="d-flex align-center">
                    <v-icon>warning</v-icon>
                    <span class="text-uppercase primary--text ml-1">delete account</span>
                    <v-spacer></v-spacer>
                </div>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-alert outlined color="error" prominent type="warning" class="mt-5">
                    <span class="font-weight-bold">Here is what happens when you delete your account</span>
                    <ul class="mt-3">
                        <li class="mt-2" v-for="(item, index) in accountDeleteConsequences" :key="index">
                            <span class="subtitle-2">
                                {{ item }}
                            </span>
                        </li>
                    </ul>
                    <v-divider class="my-4 primary" style="opacity: 0.22"></v-divider>
                    <v-checkbox :disabled="deletingAccount" class="primary--text" color="error" v-model="agreeBox" label="I understand"></v-checkbox>
                </v-alert>
            </v-card-text>
            <v-card-actions>
                <div style="width:100%" class="d-flex flex-column">
                    <div class="d-flex align-center justify-end">
                        <v-btn :disabled="deletingAccount" color="primary" text @click="deleteAccountDialog = false">close</v-btn>
                        <v-btn :disabled="!agreeBox || deletingAccount" :loading="deletingAccount" text @click="deleteAccount()" color="error">delete</v-btn>
                    </div>
                </div>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'AccountDeleteDialog',
    data() {
        return {
            agreeBox: false,
            deleteAccountDialog: false,
            deletingAccount: false,
            accountDeleteConsequences: [
                'You will lose all your roles in Nuvolos and you will not be able to login anymore with your account.',
                'Your instances and all their contents (files, tables, and application) will not be deleted but you will not be able to access them.',
                'Your instances and all their contents will be deleted after the grace period has ended.',
                'To gain access again, you will need to arrange a new invitation with a faculty member.'
            ]
        }
    },
    methods: {
        deleteAccount() {
            this.$store.dispatch('showSnackBar', {
                snackBarText: 'Deleting your account, please wait..',
                snackBarTimeout: 10000,
                snackBarIcon: 'error'
            })
            this.$data.deletingAccount = true
            this.$axios
                .delete('/users/delete_from_datahub_midpoint')
                .then(() => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'You account has been successfully deleted!',
                        snackBarTimeout: 10000,
                        snackBarIcon: 'check_circle'
                    })
                    this.$auth.logOut()
                })
                .catch(() => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Account deletion failed, please try again later or contact us via the chat in the bottom right side of the page.',
                        snackBarTimeout: 10000,
                        snackBarIcon: 'error'
                    })
                })
                .finally(() => {
                    this.$data.deletingAccount = false
                    this.$data.deleteAccountDialog = false
                })
        }
    }
}
</script>
